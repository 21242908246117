import { Container, Row, Col } from "react-bootstrap";
import { BLAButton } from "../../components/BLAButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import Carousel from "react-multi-carousel";
import leftArrow from "../../assets/img/LeftArrow.svg";
import rightArrow from "../../assets/img/RightArrow.svg";
import leftArrowWhite from "../../assets/img/LeftArrowWhite.svg";
import rightArrowWhite from "../../assets/img/RightArrowWhite.svg";

// Icones Content
import icon_content_captacao from "../../assets/img/Icon_Content_Captacao.svg";
import icon_content_prototipos from "../../assets/img/Icon_Content_Prototipos.svg";
import icon_content_design from "../../assets/img/Icon_Content_Design.svg";
import icon_content_motion from "../../assets/img/Icon_Content_Motion.svg";

// Icones Strategy
import icon_strategy_planos from "../../assets/img/Icon_Strategy_Planos.svg";
import icon_strategy_social_media from "../../assets/img/Icon_Strategy_Social_Media.svg";
import icon_strategy_seo from "../../assets/img/Icon_Strategy_SEO.svg";
import icon_strategy_branding from "../../assets/img/Icon_Strategy_Branding.svg";

import "react-multi-carousel/lib/styles.css";

export const Servicos = () => {
  const [activeContentSlide, setActiveContentSlide] = useState(0);
  const [activeStrategySlide, setActiveStrategySlide] = useState(0);

  const sliderContent = [
    {
      url: icon_content_captacao,
      name1: <><span className="yellow-text-regular">Captação</span> e <span className="yellow-text-regular">Edição</span> de</>,
      name2: "Imagem e Som",
    },
    {
      url: icon_content_prototipos,
      name1: <>Protótipos de <span className="yellow-text-regular">Website</span></>,
      name2: "",
    },
    {
      url: icon_content_design,
      name1: <span className="yellow-text-regular">Design Gráfico</span>,
      name2: "",
    },
    {
      url: icon_content_motion,
      name1: <span className="yellow-text-regular">Motion Graphics</span>,
      name2: "",
    },
  ];

  const sliderStrategy = [
    {
      url: icon_strategy_planos,
      name: <>Planos de <span className="yellow-text-regular">Comunicação</span></>,
    },
    {
      url: icon_strategy_social_media,
      name: <>Gestão de <span className="yellow-text-regular">Social Media</span></>,
    },
    {
      url: icon_strategy_seo,
      name: <>Estratégias de <span className="yellow-text-regular">SEO</span></>,
    },
    {
      url: icon_strategy_branding,
      name: <span className="yellow-text-regular">Branding</span>,
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 100 },
      items: 1,
    },
  };

  const navigate = useNavigate();

  const handlePortfolioClick = () => {
    navigate("/wip");
  };

  const handleContactsClick = () => {
    navigate("/wip");
  };

  const contentDescriptions = [
    "Disponibilizamos serviços audiovisuais, como fotografia, gravação e edição de vídeos promocionais, institucionais ou cobertura de eventos e muito mais.",
    "Criamos a estética do seu site de acordo com os seus objetivos de negócio. Visualize o futuro do seu site com protótipos inovadores.",
    "Fazemos Branding e Rebranding dos mais variados tipos de negócio e produzimos conteúdos para meios online e offline. A criação de manual de normas gráficas, logotipo, templates para redes sociais e panfletos, são alguns exemplos.",
    "Desenvolvemos peças de comunicação animadas, dinâmicas e criativas, para projetos como vídeos institucionais e de apresentação de serviços.",
  ];

  const strategyDescriptions = [
    "Realizamos uma análise interna à sua marca e, consoante os resultados obtidos, desenvolvemos as táticas mais adequadas para as suas necessidades, de forma a ganhar mais notoriedade e a atingir o seu público-alvo.",
    "Com base nos objetivos e públicos-alvo, desenvolvemos um plano de redes sociais completo, com criação do conteúdo visual, escrito e respetiva calendarização.",
    "Realizamos análises detalhadas para otimizar o desempenho do seu site nos motores de busca. Implementamos táticas personalizadas para aumentar sua visibilidade online, atrair mais tráfego qualificado e melhorar o posicionamento orgânico da sua marca.",
    "Partindo da missão, visão e valores, damos vida a vários tipos de negócios, tendo sempre em conta a sua essência.",
  ];

  return (
    <div className="servicos">
      <Container className="pb-50 container-desktop">
        <h2 className="super-title-over fs-double-title-up">OS NOSSOS</h2>
        <h2 className="super-title-under fs-double-title-down">SERVIÇOS</h2>

        <h3 className="title fs-underlined-title">CONTENT</h3>

        <div className="servicos-carousel">
          <Carousel
            id="content-carousel"
            additionalTransfrom={0}
            arrows
            centerMode={false}
            initialSlide={0}
            responsive={responsive}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={false}
            partialVisible={false}
            customLeftArrow={
              <img
                className="slider-arrow slider-arrow--left"
                src={leftArrow}
                alt="leftArrow"
              />
            }
            customRightArrow={
              <img
                className="slider-arrow slider-arrow--right"
                src={rightArrow}
                alt="rightArrow"
              />
            }
            dotListClass=""
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={true}
            beforeChange={(nextSlide) => setActiveContentSlide(nextSlide)}
          >
            {sliderContent.map((content, index) => (
              <div className="slider" key={index}>
                <img src={content.url} alt="content" />
                <p className="slider-servicos-title fs-section-title">
                  {content.name1}
                  <br />
                  {content.name2}
                </p>
              </div>
            ))}
          </Carousel>
          <p className="mt-30 fs-paragraph">{contentDescriptions[activeContentSlide]}</p>
        </div>
        <div className="servicos-grid">
          <Container>
            {/* Serviços Linha 1 */}
            <Row className="mb-50">
              <Row>
                <Col md={6}>
                  <img src={sliderContent[0].url} alt="content" />
                </Col>
                <Col md={6}>
                  <img src={sliderContent[1].url} alt="content" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderContent[0].name1}
                    <br />
                    {sliderContent[0].name2}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderContent[1].name1}
                    <br />
                    {sliderContent[1].name2}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {contentDescriptions[0]}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {contentDescriptions[1]}
                  </p>
                </Col>
              </Row>
            </Row>

            {/* Serviços Linha 2 */}
            <Row>
              <Row>
                <Col md={6}>
                  <img src={sliderContent[2].url} alt="content" />
                </Col>
                <Col md={6}>
                  <img src={sliderContent[3].url} alt="content" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderContent[2].name1}
                    <br />
                    {sliderContent[2].name2}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderContent[3].name1}
                    <br />
                    {sliderContent[3].name2}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {contentDescriptions[2]}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {contentDescriptions[3]}
                  </p>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
        <p className="cta mb-30 mt-50 fs-section-title">
          Vem descobrir os nossos <br />
         trabalhos  <span className="yellow-text">brilhantes</span>!
        </p>
        <BLAButton text="Portfólio" onClick={handlePortfolioClick} />
      </Container>
      <Container className="dark-container pb-50 container-desktop">
        <h3 className="title fs-underlined-title">STRATEGY</h3>
        <div className="servicos-carousel">
          <Carousel
            id="strategy-carousel"
            additionalTransfrom={0}
            initialSlide={0}
            responsive={responsive}
            autoPlay={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={false}
            partialVisible={false}
            customLeftArrow={
              <img
                className="slider-arrow slider-arrow--left"
                src={leftArrowWhite}
                alt="leftArrow"
              />
            }
            customRightArrow={
              <img
                className="slider-arrow slider-arrow--right"
                src={rightArrowWhite}
                alt="rightArrow"
              />
            }
            dotListClass=""
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={true}
            centerMode={false}
            beforeChange={(nextSlide) => setActiveStrategySlide(nextSlide)}
          >
            {sliderStrategy.map((strategy, index) => (
              <div className="slider" key={index}>
                <img src={strategy.url} alt="strategy" />
                <p className="slider-servicos-title fs-section-title">{strategy.name}</p>
              </div>
            ))}
          </Carousel>
          <p className="mt-30 fs-paragraph">{strategyDescriptions[activeStrategySlide]}</p>
        </div>
        <div className="servicos-grid">
          <Container>
            {/* Serviços Linha 1 */}
            <Row className="mb-50">
              <Row>
                <Col md={6}>
                  <img src={sliderStrategy[0].url} alt="strategy" />
                </Col>
                <Col md={6}>
                  <img src={sliderStrategy[1].url} alt="strategy" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderStrategy[0].name}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderStrategy[1].name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {strategyDescriptions[0]}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {strategyDescriptions[1]}
                  </p>
                </Col>
              </Row>
            </Row>

            {/* Serviços Linha 2 */}
            <Row>
              <Row>
                <Col md={6}>
                  <img src={sliderStrategy[2].url} alt="strategy" />
                </Col>
                <Col md={6}>
                  <img src={sliderStrategy[3].url} alt="strategy" />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderStrategy[2].name}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="slider-servicos-title fs-section-title">
                    {sliderStrategy[3].name}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {strategyDescriptions[2]}
                  </p>
                </Col>
                <Col md={6}>
                  <p className="fs-paragraph">
                    {strategyDescriptions[3]}
                  </p>
                </Col>
              </Row>
            </Row>
          </Container>
        </div>
        <p className="cta mb-30 mt-50 fs-section-title">
          Se ainda tiveres dúvidas <br />
          entra em contacto connosco
        </p>
        <BLAButton
          variant="dark"
          text="Contactos"
          onClick={handleContactsClick}
        />
      </Container>
    </div>
  );
};
