import React, { useState } from 'react';
import LeftArrow from '../assets/img/LeftArrow.svg';
import RightArrow from '../assets/img/RightArrow.svg';
import './AlumniCarousel.css';

export const AlumniCarousel = ({ alumni }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalAlumni = alumni.length;

  const getCircularIndex = (index) => {
    return (index % totalAlumni + totalAlumni) % totalAlumni;
  };

  const nextAlumni = () => {
    setCurrentIndex(prev => getCircularIndex(prev + 1));
  };

  const prevAlumni = () => {
    setCurrentIndex(prev => getCircularIndex(prev - 1));
  };

  const getVisibleIndices = () => {
    return {
      farLeft: getCircularIndex(currentIndex - 2),
      left: getCircularIndex(currentIndex - 1),
      center: currentIndex,
      right: getCircularIndex(currentIndex + 1),
      farRight: getCircularIndex(currentIndex + 2)
    };
  };

  const indices = getVisibleIndices();

  return (
    <div className="alumni-carousel alumni-slider-desktop">
      <div className="alumni-carousel-container">
        <div className="alumni-item alumni-far-left">
          <img 
            src={alumni[indices.farLeft].url} 
            alt={alumni[indices.farLeft].name} 
          />
        </div>
        
        <div className="alumni-item alumni-left">
          <img 
            src={alumni[indices.left].url} 
            alt={alumni[indices.left].name} 
          />
        </div>
        
        <button className="alumni-arrow alumni-arrow-left slider-arrow slider-arrow--left" onClick={prevAlumni}>
          <img src={LeftArrow} alt="Previous" />
        </button>
        
        <div className="alumni-item alumni-center">
          <img 
            src={alumni[indices.center].url} 
            alt={alumni[indices.center].name} 
          />
        </div>
        
        <button className="alumni-arrow alumni-arrow-right slider-arrow slider-arrow--right" onClick={nextAlumni}>
          <img src={RightArrow} alt="Next" />
        </button>
        
        <div className="alumni-item alumni-right">
          <img 
            src={alumni[indices.right].url} 
            alt={alumni[indices.right].name} 
          />
        </div>
        
        <div className="alumni-item alumni-far-right">
          <img 
            src={alumni[indices.farRight].url} 
            alt={alumni[indices.farRight].name} 
          />
        </div>
      </div>
    </div>
  );
};
