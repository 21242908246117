import { Col, Container, Row } from "react-bootstrap";
import logo_ESCS from "../../assets/img/logo_ESCS.svg";
import logo_Smart from "../../assets/img/logo_Smart.svg";
import logo_Magma from "../../assets/img/logo_Magma.svg";
import logo_WeBrand from "../../assets/img/logo_Webrand.svg";
import logo_EscolaDigital from "../../assets/img/logo_EscolaDigital.svg";
import logo_Flag from "../../assets/img/logo_Flag.svg";
import logo_Limpa from "../../assets/img/logo_+Limpa.svg";
import logo_LDS from "../../assets/img/logo_LDS.svg";
import logo_Refood from "../../assets/img/logo_Refood.svg";
import logo_HeForShe from "../../assets/img/logo_HeForShe.svg";
import logo_Uniarea from "../../assets/img/logo_Uniarea.svg";

export const Parceiros = () => {
  const logos = [
    { src: logo_ESCS, alt: "Escola Superior de Comunicação Social", url: "https://www.escs.ipl.pt/" },
    { src: logo_Smart, alt: "Aveiro Smart Business", url: "https://asmartbusiness.pt/" },
    { src: logo_Magma, alt: "Magma Studio", url: "https://magmastudio.pt/" },
    { src: logo_WeBrand, alt: "We Brand Agency", url: "https://webrand.agency/" },
    { src: logo_EscolaDigital, alt: "Escola Marketing Digital", url: "https://escolamarketingdigital.pt/" },
    { src: logo_Flag, alt: "Flag", url: "https://flag.pt/" },
    { src: logo_Limpa, alt: "ESCS Mais Limpa", url: "https://www.escs.ipl.pt/alunos/extracurriculares/escs-mais-limpa" },
    { src: logo_LDS, alt: "Lisbon Digital School", url: "https://lisbondigitalschool.com/" },
    { src: logo_Refood, alt: "Refood", url: "https://re-food.org/nucleos/portugal/benfica/" },
    { src: logo_HeForShe, alt: "He For She", url: "https://www.heforshelisboa.org/" },
    { src: logo_Uniarea, alt: "Uniarea", url: "https://uniarea.com/" },
  ];

  return (
    <>
      <Container className="parceiros container-desktop">
        <h3 className="title fs-underlined-title">PARCEIROS</h3>
        <Container className="parceiros-mobile">
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://www.escs.ipl.pt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={logo_ESCS}
                  alt="Escola Superior de Comunicação Social"
                />
              </a>
            </Col>
            <Col sm={6} xs={6}>
              <a
                href="https://asmartbusiness.pt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Smart} alt="Aveiro Smart Business" />
              </a>
            </Col>
          </Row>
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://magmastudio.pt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Magma} alt="Magma Studio" />
              </a>
            </Col>
            <Col sm={6} xs={6}>
              <a
                href="https://webrand.agency/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_WeBrand} alt="We Brand Agency" />
              </a>
            </Col>
          </Row>
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://escolamarketingdigital.pt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_EscolaDigital} alt="Escola Marketing Digital" />
              </a>
            </Col>
            <Col sm={6} xs={6}>
              <a
                href="https://flag.pt/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Flag} alt="Flag" />
              </a>
            </Col>
          </Row>
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://www.escs.ipl.pt/alunos/extracurriculares/escs-mais-limpa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Limpa} alt="ESCS Mais Limpa" />
              </a>
            </Col>
            <Col sm={6} xs={6}>
              <a
                href="https://lisbondigitalschool.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_LDS} alt="Lisbon Digital School" />
              </a>
            </Col>
          </Row>
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://re-food.org/nucleos/portugal/benfica/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Refood} alt="Refood" />
              </a>
            </Col>
            <Col sm={6} xs={6}>
              <a
                href="https://www.heforshelisboa.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_HeForShe} alt="He For She" />
              </a>
            </Col>
          </Row>
          <Row className="partners-row">
            <Col sm={6} xs={6}>
              <a
                href="https://uniarea.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo_Uniarea} alt="Uniarea" />
              </a>
            </Col>
          </Row>
        </Container>
        
      </Container>
      <Container className="parceiros parceiros-desktop full-width">
        <div className="marquee-container">
          <div className="marquee-content">
            {[...logos, ...logos].map((logo, index) => (
              <a
                key={index}
                href={logo.url}
                target="_blank"
                rel="noopener noreferrer"
                className="logo-item"
              >
                <img src={logo.src} alt={logo.alt} />
              </a>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};
