import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const Historia = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/wip')
    }

    return (
        <Container className="dark-container container-desktop">
            <h2 className="super-title-over fs-double-title-up">A NOSSA</h2>
            <h2 className="super-title-under fs-double-title-down">HISTÓRIA</h2>
            <p className="fs-paragraph">
            Criada em 2016, a Bright Lisbon Agency surgiu como a primeira Júnior Iniciativa da Escola Superior de Comunicação Social. Em 2021, tornou-se oficialmente a primeira Júnior Empresa do ramo da Comunicação, em Portugal.
            </p>
            <p className="mb-30 fs-paragraph">
            A BLA conta com 4 departamentos e com mais de 50 membros, atualmente. Através dos nossos serviços de Strategy e Content já trabalhámos com mais de 30 empresas, entre elas a L’oréal, a Make-a-Wish Foundation e a Fashion Revolution.
            </p>
            <BLAButton text="Conhece a nossa equipa!" variant="dark" onClick={handleClick} />
        </Container>
    )
}