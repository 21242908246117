
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"
import { ContadorHome } from "./ContadorHome"

export const OQueFazemos = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/servicos')
    }

    return (
        <Container className="dark-container o-que-fazemos">
            <ContadorHome/>
            <h3 className="title fs-underlined-title">O QUE FAZEMOS</h3>
            <div class="d-flex mob-col">
                <div className="col-md-4">
                    <h4 className="fs-section-title">Captação e edição de vídeo, fotografia e áudio</h4>
                    <p className="mb-40 fs-paragraph">Fotografamos, gravamos e editamos – uma solução audiovisual integrada, com serviços de produção e pós-produção. </p>
                </div>
                <div className="col-md-4">
                    <h4 className="fs-section-title">Design Gráfico</h4>
                    <p className="mb-40 fs-paragraph">Criamos a identidade gráfica da sua marca, desenvolvemos conteúdo gráfico para online e offline – em poucas palavras damos vida à ideia que não sai da sua cabeça.
                    </p>
                </div>
                <div className="col-md-4">
                    <h4 className="fs-section-title">Motion Graphics</h4>
                    <p className="mb-30 fs-paragraph">Desenvolvemos peças de comunicação animadas,
                    criando uma narrativa dinâmica e criativa que gera maior impacto visual.</p>
                </div>
            </div>
            <BLAButton text="Serviços" variant="dark" onClick={handleClick}/>
        </Container>
    )
}