
import { Accordion, Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const FAQs = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/contacts')
    }

    return (
        <Container className="faq">
            <h3 className="title">FAQs</h3>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="fs-accordion-header"><p className="side-yellow-text fs-accordion-header">O que é uma <span className="yellow-text fs-accordion-header">Júnior Empresa?</span></p></Accordion.Header>
                    <Accordion.Body className="fs-accordion-body">
                    Uma Júnior Empresa é uma organização sem fins lucrativos, formada e gerida exclusivamente por estudantes universitários.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className="fs-accordion-header"><p className="side-yellow-text fs-accordion-header">Qual é o ramo de trabalho da <span className="yellow-text fs-accordion-header">Bright Lisbon Agency?</span></p></Accordion.Header>
                    <Accordion.Body  className="fs-accordion-body">
                    A Bright Lisbon Agency é a única Júnior Empresa nacional especializada em comunicação!
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header className="fs-accordion-header"><p className="side-yellow-text fs-accordion-header">Quem são os maiores clientes que a <span className="yellow-text fs-accordion-header">Bright Lisbon Agency</span> já teve?</p></Accordion.Header>
                    <Accordion.Body className="fs-accordion-body">
                    Acreditamos que todos os nossos clientes têm o seu valor, cada um da sua forma. De qualquer das formas, deixámos alguns exemplos no nosso portfólio!
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p className="cta mb-30 mt-50 fs-section-title">Se ainda tiveres dúvidas <br/>entra em contacto connosco</p>
            <BLAButton text="Contactos" onClick={handleClick} />
        </Container>
    )
}