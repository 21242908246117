import { React, useState } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { BLAButton } from "../../components/BLAButton";
import iconLinkedIn from "../../assets/img/icon_linkedin.svg";
import iconInstagram from "../../assets/img/Instagram.svg";
import iconEmail from "../../assets/img/icon_Email.svg";
import iconLocation from "../../assets/img/icon_Location.svg";

export const Contacts = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: "",
      email: "",
      message: "",
    });
    try {
      console.log("enviar email:", formData);
      window.open(
        `mailto:geral@brightlxagency.com?subject=Novo Contacto de ${formData.name}&body=Olá, BLA!%0D%0A%0D%0A%0D%0A${formData.message}`
      );

      // Optional: Clear form after successful submission
      setFormData({
        name: "",
        email: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <Container className="dark-container contacts">
      <div className="contacts-container">
        <h3 className="title">CONTACTOS</h3>
        <Row className="contacts-mobile">
          <Col
            md={6}
            className="d-flex contacts-cell flex-column justify-content-center"
          >
            <Form className="form-contact" onSubmit={handleSubmit}>
              <Form.Group className="mb-30" controlId="form-contact-name">
                <Form.Control
                  className="form-contact-input"
                  type="text"
                  placeholder="Nome"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-30" controlId="form-contact-email">
                <Form.Control
                  className="form-contact-input"
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-30" controlId="form-contact-name">
                <Form.Control
                  className="form-contact-input"
                  as="textarea"
                  placeholder="Mensagem"
                  rows={8}
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <BLAButton text="Enviar" variant="dark" type="submit" />
            </Form>
          </Col>
          <Col
            md={6}
            className="d-flex contacts-cell flex-column align-items-center justify-content-center"
          >
            <div className="contact-info d-flex flex-column">
              <div className="contact-info-socials">
                <div className="social-icon">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/brightlxagency/"
                  >
                    <img src={iconLinkedIn} alt="LinkedIn" />
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/brightlxagency/"
                  >
                    <img src={iconInstagram} alt="Instagram" />
                  </a>
                </div>
              </div>

              <div className="contact-details">
                <div className="contact-details-item">
                  <img
                    alt="E-mail"
                    src={iconEmail}
                    className="contact-details-item-icon"
                  ></img>
                  <p className="contact-details-item-text fs-paragraph">
                    geral@brightlxagency.com
                  </p>
                </div>
                <div className="contact-details-item">
                  <img
                    alt="Location"
                    className="contact-details-item-icon"
                    src={iconLocation}
                  ></img>
                  <p className="contact-details-item-text fs-paragraph">
                    Campus de Benfica do IPL 1549-014 Lisboa
                  </p>
                </div>
              </div>
              <div className="contact-map">
                <iframe
                  title="Mapa Escola Superior de Comunicação Social"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1555.887040879151!2d-9.195543760807643!3d38.74594619293923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1eccd4897cc759%3A0x6a13e07c0070e02a!2sEscola%20Superior%20de%20Comunica%C3%A7%C3%A3o%20Social%20(ESCS)!5e0!3m2!1spt-PT!2spt!4v1734515372599!5m2!1spt-PT!2spt"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
        <div className="contacts-desktop">
          <Form className="form-contact" onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className="mb-30">
                <Form.Group controlId="form-contact-name">
                  <Form.Control
                    className="form-contact-input"
                    type="text"
                    placeholder="Nome"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}></Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group controlId="form-contact-email">
                  <Form.Control
                    className="form-contact-input mb-30"
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <div className="contact-details">
                  <div className="contact-details-item">
                    <img
                      alt="E-mail"
                      src={iconEmail}
                      className="contact-details-item-icon"
                    ></img>
                    <p className="contact-details-item-text">
                      geral@brightlxagency.com
                    </p>
                  </div>
                  <div className="contact-details-item">
                    <img
                      alt="Location"
                      className="contact-details-item-icon"
                      src={iconLocation}
                    ></img>
                    <p className="contact-details-item-text">
                      Campus de Benfica do IPL 1549-014 Lisboa
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="contact-form-message mb-30">
                <Form.Group controlId="form-contact-name">
                  <Form.Control
                    className="form-contact-input h-100"
                    as="textarea"
                    placeholder="Mensagem"
                    rows={14}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-30">
                <div className="contact-map h-100">
                  <iframe
                    title="Mapa Escola Superior de Comunicação Social"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1555.887040879151!2d-9.195543760807643!3d38.74594619293923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1eccd4897cc759%3A0x6a13e07c0070e02a!2sEscola%20Superior%20de%20Comunica%C3%A7%C3%A3o%20Social%20(ESCS)!5e0!3m2!1spt-PT!2spt!4v1734515372599!5m2!1spt-PT!2spt"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <BLAButton text="Enviar" variant="dark" type="submit" />
              </Col>
              <Col md={6} className="d-flex justify-content-center">
                <div className="contact-info-socials">
                  <div className="social-icon">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/brightlxagency/"
                    >
                      <img src={iconLinkedIn} alt="LinkedIn" />
                    </a>
                  </div>
                  <div className="social-icon">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/brightlxagency/"
                    >
                      <img src={iconInstagram} alt="Instagram" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Container>
  );
};
