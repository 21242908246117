
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
export const OQueFazemosAU = () => {
    return (
        <Container className="dark-container container-desktop">
            <h3 className="title fs-underlined-title">O QUE FAZEMOS</h3>
            <h4 className="mb-30 fs-section-title">Desenvolvemos projetos nas áreas de Content e Strategy:</h4>
            <p className="mb-30 fs-paragraph">
            Procuramos ajudar os nossos clientes a elevar a sua comunicação, funcionando como uma agência de comunicação estando sempre atento às necessidades de cada cliente. Seja na área de strategy ou de content, na Bright Lisbon Agency procuramos trazer o melhor da empresa e aumentar a sua notoriedade no mercado onde se insere.</p>
            <BLAButton text="Saber mais" variant="dark"/>
        </Container>
    )
}