import wip_img from "../../assets/img/wip.svg"
import { Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const WIP = () => {

    const navigate = useNavigate()

    const handleBackClick = () => {
        navigate(-1)
    }

    const handleHomeClick = () => {
        navigate('/')
    }

    return (
        <Container className="wip-container-desktop">
            <img src={wip_img} alt="Página em Construção"></img>
            <BLAButton text="Home" onClick={handleHomeClick}/>
            <BLAButton text="Voltar" onClick={handleBackClick} />
        </Container>
    )
}