
import { Accordion, Container } from "react-bootstrap"
import { BLAButton } from "../../components/BLAButton"
import { useNavigate } from "react-router-dom"

export const Recrutamento = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/aboutus')
    }
    return (
            <div className="recrutamento">
            <Container className="dark-container pb-50 recrutamento-container">
                <h2 className="super-title-over fs-double-title-up">O NOSSO</h2>
                <h2 className="super-title-under-recrutamento">RECRUTAMENTO</h2>
                <p className="fs-paragraph">
                Queres brilhar na Bright Lisbon Agency? O recrutamento abre uma vez por semestre. Preenche o formulário e dá o primeiro passo para te juntares à nossa equipa!
                </p>
                <p className="cta mb-30 mt-50 cta-recrutamento fs-section-title">Enquanto o recrutamento não abre,<br/>aprende mais acerca da <span className="yellow-text">bla</span></p>
                <BLAButton variant="dark" text="Sobre Nós" onClick={handleClick} />
            </Container>
            <Container className="recrutamento-processo-container">
                <h3 className="title fs-underlined-title">Processo</h3>
                <p className="fs-paragraph">
                O nosso processo de recrutamento é dividido em 4 fases, que ocorrem no início de cada semestre. Cada etapa é projetada para avaliar as tuas habilidades e o teu potencial de integração na nossa equipa.
                </p>
                <h4 className="dark-subtitle mt-40 fs-section-title">Fases</h4>
                <div className="list">
                    <ol>
                        <li className="fs-paragraph">
                            <b>Formulário de Inscrição: </b>
                            Preenche o formulário com os teus dados e escolhe o departamento que mais te interessa.
                        </li>
                        <li className="fs-paragraph">
                            <b>Dinâmica de grupo: </b>
                            Nesta fase, irás participar em atividades e desafios de grupo, onde poderás demonstrar o teu espírito de equipa e ver como te adaptas a trabalhar com a equipa.
                        </li>
                        <li className="fs-paragraph">
                            <b>Briefing Individual: </b>
                            Resolverás um desafio personalizado, relacionado com o departamento ao qual te inscreveste. Este é o momento para mostrares o teu conhecimento e criatividade.
                        </li>
                        <li className="fs-paragraph">
                            <b>Entrevista Individual: </b>
                            A última etapa é uma conversa individual, onde ficamos a conhecer um pouco mais sobre ti e por que acreditas ser a pessoa ideal para te juntares à nossa equipa.
                        </li>
                    </ol>
                </div>
            </Container>
            <Container className="dark-container pb-50 recrutamento-container">

            <h3 className="title fs-underlined-title">DEPARTAMENTOS</h3>

            <h4 className="yellow-text-regular mb-20 mt-40 fs-section-title">Comunicação Estratégica (CE)</h4>
            <p className="fs-paragraph">
            O departamento de Comunicação Estratégica (CE) realiza uma variedade de serviços, incluindo o desenvolvimento de planos de comunicação e media, gestão de redes sociais, estratégias de SEO e projetos de branding, adaptados às necessidades específicas de cada cliente ou projeto.
            </p>
            <h4 className="yellow-text-regular mb-20 mt-40 fs-section-title">Recursos Humanos (RH)</h4>
            <p className="fs-paragraph">
            O departamento de Recursos Humanos, é a “cola” de toda a estrutura que é a Bright Lisbon Agency. Este é responsável por garantir o bem-estar de todos os membros da júnior empresa. Para além disso, é da sua responsabilidade a coordenação das presenças dos membros para as atividades da júnior empresa, desde atividades externas, a institucionais ou até internas.
            </p>
            <h4 className="yellow-text-regular mb-20 mt-40 fs-section-title">Design e Imagem (DI)</h4>
            <p className="fs-paragraph">
            Os membros de Design e Imagem (DI) dão vida à comunicação dos seus clientes. Dedicam-se ao design gráfico, criando identidades visuais únicas para marcas e integrando-as de forma coesa nas suas ações de comunicação. Além disso, especializam-se na captação e edição de conteúdos, produzindo materiais de fotografia, vídeo e áudio para eventos e campanhas, bem como motion graphics. 
            </p>


                <h3 className="title fs-underlined-title">FAQs</h3>
                
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><p className="fs-accordion-header">Posso inscrever-me, sendo estudante de <span className="yellow-text">1º ano</span>, ou não tendo muita/qualquer <span className="yellow-text">experiência</span>?</p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><p className="fs-accordion-header">Não tenho uma <span className="yellow-text">foto profissional</span>, posso inscrever-me?</p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><p className="fs-accordion-header">Posso inscrever-me, mesmo sendo <span className="yellow-text">estudante de mestrado?</span></p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><p className="fs-accordion-header">Faz sentido inscrever-me, mesmo sendo <span className="yellow-text">estudante de Jornalismo?</span></p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><p className="fs-accordion-header">Penso em inscrever-me, mas tenho medo de <span className="yellow-text">não ter tempo</span> para tudo...</p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><p className="fs-accordion-header">Posso inscrever-me, mesmo sendo <span className="yellow-text">estudante de ERASMUS?</span> / Can I sign up, even if I am an <span className="yellow-text">ERASMUS student</span>?</p></Accordion.Header>
                        <Accordion.Body className="fs-accordion-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </div>
    )
}